<template>
    <div class="Head-box">
        <div class="bg-img-box">
            <img class="img" src="~assets/img/bg.png" alt="标题头">
            <img class="img1" src="~assets/img/bgg.png" alt="标题头">
        </div>
        <div class="nav-box">
            <span class="ul-box">北京睿易博达科技有限公司</span>
        </div>
        <div class="nav-router" :style="{background:'#4695E6'}">
            <!-- <div class="nav-item">
                <router-link  active-class="active" :to="linkeTo">数据服务</router-link>
            </div>
            <div style="margin-left:6px;width: 2px;height: 50px;background: #fff;"></div> -->
            <div class="nav-item">
                <router-link  active-class="active" to="/ItemServiceUp">合作策划</router-link>
            </div>
            <div style="margin-left:6px;width: 2px;height: 50px;background: #fff;"></div>
            <!--<div class="nav-item">
                <router-link active-class="active" to="/ProjectUp">气象服务</router-link>
            </div>
            <div style="margin-left:6px;width: 2px;height: 50px;background: #fff;"></div>-->
            <div class="nav-item">
                <router-link active-class="active" to="/TecServiceUp">技术服务</router-link>
            </div>
            <div style="margin-left:6px;width: 2px;height: 50px;background: #fff;"></div>

        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                linkeTo: "/",
            }
        },
        beforeMount() {
            this.activeClass();
        },
        methods: {
            activeClass() {
                var url = this.$route.path;
                this.linkeTo = url;
                if (url == "/WeatherUp" || url == "/") {
                }  else if (url == "/ProjectUp" || url == "/ItemServiceUp" || url == "/TecServiceUp"  ) {
                    this.linkeTo = "/WeatherUp";
                } else {
                    this.linkeTo = "/WeatherUp";
                }
            }
        },
        watch: {
            $route() {
                this.activeClass();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Head-box {
        width:1200px;margin: 0 auto;
        height: auto;
        position: relative;
    .bg-img-box {
        width: 100%;
        height:110px;
        position: relative;
        .img {
            width: 100%;
            height: 100%;
        }
        .img1{
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            height: 100%;
        }
    }

    .nav-router {
        width: 100%;
        margin-top: -4px;
        height: 50px;
        line-height: 50px;
        position: relative;
        display: flex;

    .nav-item {
        margin-left:12px;
        height: auto;
        cursor: pointer;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        position: relative;
        font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        :hover{
            color: red;
        };
    }

        .active {
            color: red;
        }
     }
    }
    .nav-box {
        height: auto;
        display: flex;
        position: absolute;
        top: 6px;
        left: 48%;
        .ul-box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            font: 37px/100% "微软雅黑", "Lucida Grande", "Lucida Sans", Helvetica, Arial, Sans;;
            letter-spacing:3px;
            color:#1262B3;
            /*text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.3),0 3px 5px rgba(0,0,0,0.2),0 5px 10px rgba(0,0,0,0.25);*/
        }
    }
</style>
